import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const DashBoardApi = createApi({
  reducerPath: "DashBoardApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    dashboard_metric: builder.query({
      query: ({StartDateEnhanced, EndDateEnhanced}) => ({
        url: `/metrics/orders-range?startDate=${moment(StartDateEnhanced).valueOf() / 1000
        }&endDate=${moment(EndDateEnhanced).valueOf() / 1000}`,
        method: "GET",
      }),
    }),
    graphData: builder.query({
      query: ({monthId}) => ({
        url: `/metrics/charts?months=${monthId}`,
        method: "GET",
      }),
    }),
    topAreasData: builder.query({
      query: (month) => ({
        url: `/metrics/top-areas`,
        method: "GET",
      }),
    }),
    AllSignups: builder.query({
      query: (month) => ({
        url: `/auth/signups`,
        method: "GET",
      }),
    }),
    mainDashBoardMap: builder.query({
      query: (companyId) => ({
        url: `company/staffs-locations/${companyId}`,
        method: "GET",
      }),
    }),
    fieldReps: builder.query({
      query: (companyId) => ({
        url: `/company/sales-rep`,
        method: "GET",
      }),
    }),
    getAllCompanies: builder.query({
      query: (companyId) => ({
        url: `/company/all`,
        method: "GET",
      }),
    }),
    addCompany: builder.mutation({
      query: (payload) => ({
        url: `/company`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
    addCompanyAdmin: builder.mutation({
      query: (payload) => ({
        url: `/auth/company-admin/create`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useDashboard_metricQuery,
  useGraphDataQuery,
  useTopAreasDataQuery,
  useMainDashBoardMapQuery,
  useFieldRepsQuery,
  // 
  useGetAllCompaniesQuery,
  useAddCompanyMutation,
  useAddCompanyAdminMutation,
    useAllSignupsQuery,
} = DashBoardApi;
