import React,{useState,useEffect} from 'react'
import ListViewInCanvas from '../../../Common/ListViewInCanvas'
// import { ImageComponent } from '../../../Common/FallbackImage'
import moment from 'moment'
import { statusStyleII } from '../Data'
import { IoIosArrowUp } from 'react-icons/io'
import { ImageComponent } from '../../../Common/FallbackImage'
import { BsThreeDotsVertical } from 'react-icons/bs'
import SelectComp from '../../../Ui/SelectComp'
import Beta from '../../../Common/beta'


export default function ViewCustomerDetailsComp({data,edit,handleSubmit}) {
   
    const [userData, setUserData] = useState(data);
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
        handleSubmit(userData)
        // console.log(e.target)
      };

      
      useEffect(() => {
        handleSubmit(userData)
      }, [handleSubmit, userData])
      
  return (
   <>
   { <div>
        <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px]  xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]'>
       <div className='row gy-4'>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Company Name'}
            ItemName={userData?.company_name}
            edit={edit}
            name='company_name'
            onChange={handleOnChange}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Contact Person'}
            ItemName={userData?.contact_person}
            edit={edit}
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Country'}
            ItemName={ userData?.country}
            edit={edit}
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'State'}
            ItemName={ userData?.state}
            disabled={true}
            edit={edit}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Phone Contact'}
            ItemName={ userData?.phone_number}
            edit={edit}
            name='phone_number'
            onChange={handleOnChange}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Email'}
            ItemName={userData?.email}
            edit={edit}
            name='email'
            onChange={handleOnChange}
            />
        </div>
        
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Date Added'}
            ItemName={moment(userData?.createdAt).format("MMM Do YY")}
            edit={edit}
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Last Activity'}
            ItemName={moment(data?.updatedAt).format("MMM Do YY, h:mm:ss a")}
            edit={edit}
            type='date'
            disabled={true}
            />
        </div>
       
        
       
        
       </div>
    </div>
    {/* <Beta position={'right'}/> */}

    {/* <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[20px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]'>
        <div className='flex justify-between mb-[8px]'>
            <div className='font-medium text-[#A1A1A1]'>
            Assigned To
            </div>
            <div className='text-[14px] text-[#3DA84A] font-medium flex gap-2 items-center'>
            View all activity <IoIosArrowUp/>
            </div>
        </div>

        <div className='flex justify-between items-center mb-5'>
            <div className='flex gap-3 items-center'>
                <div>
                    <ImageComponent
                    src='/Images/noUser.jpg'
                    width={70}
                    height={70}
                    />
                </div>
                <div className='flex items-start gap-3'>
                  <div>
                  <div className='text-[#282728] font-medium text-[14px]'>-</div>
                    <div className='text-[#757575] text-[12px] font-medium'>-</div>
                  </div>
                  <div>
                  <ImageComponent
                    src='/icon/star.svg'
                    width={16}
                    height={16}
                    />
                </div>
                  
                </div>
                </div>
              
            <div>
                <BsThreeDotsVertical size={20} color='#4F008A'/>
            </div>
        </div>

        <div>
        <div className='flex justify-between mb-[15px]'>
            <div className='font-medium text-[#4F008A]'>
            Notes (4)
            </div>
            <div className='text-[14px] text-[#3DA84A] font-medium flex gap-2 items-center'>
            View all activity <IoIosArrowUp/>
            </div>
        </div>

        <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px]   flex-1 mb-[30px]'>
        <div className='flex justify-between mb-[8px] border-b-[1px] px-2 py-3'>
            <div className='font-medium text-[#000000] text-[12px]'>
            Note By Olakunbi Olabode
            </div>
            <div className='text-[12px] text-[#000000] font-medium flex gap-2 items-center'>
            Today, 12:00AM
            </div>
        </div>
        <div className='text-[#555555] text-[12px] py-3 px-3'>
        Lorem ipsum dolor sit amet consectetur. Enim molestie suspendisse ultrices nibh orci egestas vel. Fermentum volutpat leo dignissim proin dolor tincidunt.
        </div>
            </div>
        </div>
</div> */}
    {/* <div className='flex justify-center gap-[20px]'>
        <ButtonComp
        text={'Delete Customer'}
        btnClassName={'border-[#D63434] border-[1px] text-[#282728] rounded-full px-[25px] py-2 text-[14px] font-medium'}
        />
        <ButtonComp
        text={'Edit Customer'}
        btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium'}
        />
    </div> */}
    </div>}
    <div >
   
    </div>
   </>
  )
}
