import React from "react";
import InputComp from "../../../Ui/InputComp";
import useFormValidator from "use-form-input-validator";
import {
  useAddCompanyAdminMutation,
  useGetAllCompaniesQuery,
} from "../../../../store/DashBoard/DashBoardApi";
import ButtonComp from "../../../Ui/Button";
import SelectComp from "../../../Ui/SelectComp";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function AddCompanyAdmin() {
  const { data: getCompanies } = useGetAllCompaniesQuery();
  const [addCompanyAdmin, { isLoading, isSuccess }] =
    useAddCompanyAdminMutation();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
   formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      companyId: "",
      email: "",
    
    },
  });

   console.log(errors,'errors')

  const handleAddCompanyAdmin = async(e) => {
    
      const payload ={
        ...e,
        "userRole": "company_admin",
        "userType":"supplier"
      }

      const response =await addCompanyAdmin(payload);
        if(response?.data?.id){
          toast.success('Manager Added Successfully...');
          reset()
        }

        if(response?.error){
          toast.error(response?.error?.data?.message)
        }
     

      console.log(response,'response')
    
  };

  return (
    <div>
      <div className="row gx-4 border p-3 m-3 gy-4">
        <div className="col-lg-6">
          <Controller
            name={"firstName"}
            control={control}
            rules={{
              required: `First Name is required`,
              // pattern: item?.pattern,
              // maxLength: generateMaxLength(6),
            }}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              const errorMessage = errors["firstName"]?.message;
              // console.log(errors,'errors')
              return (
                <InputComp
                  label={"Manager’s First Name"}
                  labelClassName={"primaryI mb-2"}
                  placeholder={"Enter First Name"}
                  name="firstName"
                  {...{
                    value,
                    onChange,
                    errors: [errorMessage],
                  }}
                />
              );
            }}
          />
          <div className="text-[12px] text-danger">{errors?.firstName?.message}</div>
        </div>
        
        <div className="col-lg-6">
        <Controller
          name={"lastName"}
          control={control}
          rules={{
            required: `Last Name is required`,
            // pattern: item?.pattern,
            // maxLength: generateMaxLength(6),
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const errorMessage = errors["lastName"]?.message;
            // console.log(errors,'errors')
            return (
              <InputComp
                label={"Manager’s Last Name"}
                labelClassName={"primaryI mb-2"}
                placeholder={"Enter First Name"}
                name="lastName"
                {...{
                  value,
                  onChange,
                  errors: [errorMessage],
                }}
              />
            );
          }}
        />
                  <div className="text-[12px] text-danger">{errors?.lastName?.message}</div>

        </div>

        <div className="col-lg-6">
          <Controller
            name={"email"}
            control={control}
            rules={{
              required: `Email is required`,
              // pattern: item?.pattern,
              // maxLength: generateMaxLength(6),
            }}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              const errorMessage = errors["email"]?.message;
              // console.log(errors,'errors')
              return (
                <InputComp
                  label={"Email Address"}
                  labelClassName={"primaryI mb-2"}
                  placeholder={"Enter Email"}
                  name="email"
                  {...{
                    value,
                    onChange,
                    errors: [errorMessage],
                  }}
                />
              );
            }}
          />
                    <div className="text-[12px] text-danger">{errors?.email?.message}</div>

        </div>
        <div className="col-lg-6">
          <Controller
            name={"companyId"}
            control={control}
            rules={{
              required: `Company is required`,
              // pattern: item?.pattern,
              // maxLength: generateMaxLength(6),
            }}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              const errorMessage = errors["firstName"]?.message;
              // console.log(errors,'errors')
              return (
                <SelectComp
                  label={"Company"}
                  labelClassName={"primaryI mb-2"}
                  placeholder={"Enter First Name"}
                  name="companyId"
                  options={getCompanies?.data}
                  {...{
                    value,
                    onChange,
                    errors: [errorMessage],
                  }}
                />
              );
            }}
          />
                    <div className="text-[12px] text-danger">{errors?.companyId?.message}</div>

        </div>
        <div className="mt-5 mb-2">
          <div className="flex justify-center  mb-[64px] gap-[15px]">
            <ButtonComp
              text={"Discard"}
              btnClassName={
                "text-[14px] lg:text-[16px]  py-[5px] lg:py-[8px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
              }
            />
            <ButtonComp
              onClick={handleSubmit(handleAddCompanyAdmin)}
              btnClassName={
                "text-[14px] lg:text-[16px] py-[5px] lg:py-[8px] px-[15px] lg:px-[57px] border-[0.64px] rounded-full border-[#4F008A] text-[#fff] bg-[#4F008A]"
              }
              text={isLoading ? "Loading..." : "Add New Manager"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
