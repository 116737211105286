import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useNavigate } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import TextareaComp from "../../Ui/TextArea";
import ModalComp from "../../Ui/ModalComp";
import { AiFillPlusCircle } from "react-icons/ai";
import {MdClose} from 'react-icons/md'
import useFormValidator from "use-form-input-validator";
import {
  useAddProductMutation,
  useGetCategoriesQuery,
} from "../../../store/Product/ProductApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import AddProductCsv from "./submodules/AddProductCsv";
import AddDynamicPricing from "./submodules/AddSynamicPrincing";
import { useAddCompanyAdminMutation, useAddCompanyMutation, useGetAllCompaniesQuery } from "../../../store/DashBoard/DashBoardApi";
import OffCanvasComp from "../../Ui/OffCanvas";
import AddCompanyAdmin from "./submodules/AddCompanyAdmin";

export default function AddCompanyComp() {
  const { t } = useTranslation();

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showDynamicPricing, setShowDynamicPricing] =useState(false)
  const [productCsv,setProductCsv] =useState(false);
  const [showModal,setShowModal]=useState(false)
  const navigate = useNavigate();
  const [picture, setPicture] = useState(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


  const [
    addCompany,
    { isLoading: AddproductLoader, isSuccess: AddProductIsSuccess },
  ] = useAddCompanyMutation();
 

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    name: { checks: "required", value: "" },
    contactPerson: { checks: "required", value: "" },
    address: { checks: "required", value: "" },
    email: { checks: "required|email", value: "" },
    productImageCheck: { checks: "required", value: "" },
    state: { checks: "required", value: "" },
    contactPhoneNo: { checks: "required|num", value: "" },
    country: { checks: "required", value: "" },
  
  });
 

  const handleAddProduct = (e) => {
    e.preventDefault();
    // if (isAllFieldsValid() && productCategory && status) {
    if (isAllFieldsValid()) {

      const fileSize = picture[0]?.size / 1024; // Convert file size to KB

  if (fileSize > 300) {
    alert("Please select an image smaller than 300KB.");
    // Optionally, you can reset the input value to allow the user to select a different file
    event.target.value = null;
    return;
  }
      const form = new FormData();
        form.append("name", values?.name);
        form.append("contactPerson", values?.contactPerson);
        form.append("address", values?.address);
        form.append("email", values?.email);
        form.append("state", values?.state);
        form.append("companyLogo", picture[0]);
        form.append("contactPhoneNo", values?.contactPhoneNo);
        form.append("country", values?.country);
        // addCompany(form);

        addCompany(form)
        .unwrap()
        .then((data) => {
          // Toast
          toast.success("Company Added Successfully...");

          // console.log(data,'addProduct')
        })
        .catch((err) => {
          console.log(err);
          if (Array.isArray(err?.data?.message)) {
            toast.error(err?.data?.message[0]);
          } else {
            toast.error(err?.data?.message);
          }
        });

      // console.log(values, "values");
    }
  };

 

  useEffect(() => {
    if (AddProductIsSuccess) {
      setShowAddProductModal(true);
    }
  }, [AddProductIsSuccess]);


  const handleSubmitDynamic = () =>{
    toast.success('Add successfully...')
  }



  // console.log(values, errors, "getCategories");

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h4
          className="flex gap-2 items-center font-semibold"
          onClick={() => navigate(-1)}
        >
          <ImageComponent src="/icon/back.svg" className="cursor-pointer" /> Add
          New Company
        </h4>
        <ButtonComp
        onClick={()=>setShowModal(true)}
          btnClassName={"px-4 py-[10px] rounded-pill bg-primaryI text-white"}
          text={
            <span className="flex gap-2 ">
              {" "}
              {/* <ImageComponent
                src="/icon/upload.png"
                width={24}
                height={24}
                className="cursor-pointer"
              /> */}
              Add Manager
            </span>
          }
        />
      </div>
      {/*  */}
      <div className="flex flex-wrap lg:flex-nowrap justify-between mb-5">
        <div className="w-full lg:w-[45%]">
          <div className="pr-[20px] col-12">
            
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              defaultValue={values?.name}
              label={"Company Name"}
              placeholder={"Enter Product Name"}
              name="name"
              onChange={updateField}
              error={errors.name}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              label={'Address'}
              defaultValue={values?.contactPerson}
              placeholder={"Enter Address"}
              name="address"
              onChange={updateField}
              error={errors.address}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              label={'State'}
              name="state"
              defaultValue={values?.state}
              onChange={updateField}
              error={errors.state}
            />
           
            
            
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              label={'Contact Person No'}
              name="contactPhoneNo"
              onChange={updateField}
              error={errors.contactPhoneNo}
              defaultValue={values?.contactPhoneNo}
            />
          </div>
        </div>
        <div className="col-lg-1 text-center flex justify-center">
          <div className="border-r-2 h-full border-[#4F008A]"></div>
        </div>
        <div className="w-full lg:w-[45%]">
          <div className="pr-[20px]">
          <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              defaultValue={values?.contactPerson}
              label={'Contact Person'}
              placeholder={"Enter Contact Person Name"}
              name="contactPerson"
              onChange={updateField}
              error={errors.contactPerson}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              label={'Email'}
              placeholder={"Enter Email"}
              name="email"
              // type="number"
              defaultValue={values?.email}
              onChange={updateField}
              error={errors.email}
              
            />

            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI mb-2"}
              label={'Country'}
              placeholder={'Enter Country'}
              // type="country"
              name="country"
              onChange={updateField}
              error={errors.country}
              defaultValue={values?.country}
              // prefixIcon={<span>date</span>}
              // placeholder={'Enter Product Price'}
            />
            
            <div>
              <div className={`mb-[10px] font-medium primaryI`}>
                {"Product Image"}
              </div>
              <div className="flex gap-[10px] items-center">
                <ImageComponent
                  src={image || "/Images/noImage.png"}
                  className="w-[64px] h-[64px]"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  name="productImageCheck"
                  // onChange={updateField}
                  error={errors.productImage}
                  onChange={(e) => {
                    setPicture(e.target.files);
                    //convert Image to be display
                    handleImageChange(e);
                    updateField(e);
                  }}
                />
                <ButtonComp
                  onClick={handleButtonClick}
                  text={"Choose Photo"}
                  btnClassName={
                    "px-[32px] py-[10px] bg-[#F6F2F9] text-[#282728] font-medium"
                  }
                />
                <div>(Only *.jpeg and *.png)</div>
                <div></div>
              </div>
              {errors.productImageCheck && (
                <div className="text-danger text-start ps-1">
                  {"Picture"} {errors?.productImageCheck}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-5">
        <ButtonComp
          loader={AddproductLoader}
          onClick={(e) => handleAddProduct(e)}
          text={"Add Company"}
          btnClassName={"bg-[#3DA84A] text-white px-5 py-[10px] rounded-pill"}
        />
      </div>
      <ModalComp
        show={showAddProductModal}
        bodyComponents={<AddProductModal navigate={navigate} />}
        handleClose={() => setShowAddProductModal(false)}
      />
      <ModalComp
      show={productCsv}
      size='lg'
      handleClose={()=>setProductCsv(false)}
      bodyComponents={
      <AddProductCsv setProductCsv={setProductCsv}/>}
      />
      <ModalComp
      show={showDynamicPricing}
      handleClose={()=>setShowDynamicPricing(false)}
      size={'lg'}
      bodyComponents={
       <AddDynamicPricing setShowDynamicPricing={setShowDynamicPricing}/>
      }
      />
      <OffCanvasComp
      show={showModal}
      handleClose={()=>setShowModal(false)}
      title={'Add New Manager'}
      bodyComponent={<div>
       <AddCompanyAdmin/>
      </div>}
      />
    </div>
  );
}

const AddProductModal = ({ navigate }) => {
  return (
    <>
      <div className="flex justify-center mb-[40px]">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
        Company Successfully Created 
        </h5>
        <div className="text-[16px]">
        The company has been successfully added and you can easily create company managers
        </div>
      </div>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          onClick={() => navigate("/")}
          text={"Go to dashboard"}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#3DA84A] text-[#3DA84A]"
          }
        />
        <ButtonComp
          onClick={() => navigate("/companies/add")}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={"Create Managers"}
        />
      </div>
    </>
  );
};
